:root {
  --appHeaderHeight: 1px;
  --appContentTopPadding: 1px;
  --appContentHeight: calc(
    100vh - var(--appHeaderHeight) - var(--appContentTopPadding)
  );
}

.appHeader {
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 1em;
  height: var(--appHeaderHeight);
}

.appHeader h4 {
  overflow: hidden;
}

.appHeaderActions {
  list-style: none;
  white-space: nowrap;
}

.appHeaderActions > li {
  margin-top: 1rem;
}

.appHeaderActions a {
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  padding: 1.5rem 1rem;
}

.appHeaderActions a:hover {
  color: white;
}

div.appContent {
  height: calc(100vh - var(--appHeaderHeight));
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.5);
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
